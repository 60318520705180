import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogin: false,
}

export const authenticated = createSlice({
  name: 'authenticated',
  initialState,
  reducers: {
    setIsAuthenticated: (state) => {
      state.isLogin = true
    },
    setIsNotAuthenticated: (state) => {
      state.isLogin = false
    },
  },
})

export const { setIsAuthenticated, setIsNotAuthenticated} = authenticated.actions

export default authenticated.reducer