import React, { createContext, useState, useEffect } from 'react'
import { getRefreshToken, getToken, useToken } from 'Api/token'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchProfile } from 'Api/profile'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAuthenticated } from 'Store/authenticated'
import Toast from 'Utils/toast'
import { useTranslation } from 'react-i18next'
import {
    getCnLanguage,
    getEnLanguage,
    getIdLanguage,
    getLanguage,
} from 'Api/lang'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    // const { t } = useTranslation()
    // const language = t('LANGUAGE')
    const [newLang, setNewlang] = useState({})

    useEffect(() => {
        const isLoggedin = localStorage.getItem('isLoggedin')
        const token = localStorage.getItem('token')

        if (!token || !isLoggedin) {
            mutateToken()
        }
        mutateProfile({ token })
    }, [])

    useEffect(() => {
        const lang = localStorage.getItem('lang')

        if (!lang) localStorage.setItem('lang', 'CN')
    }, [])

    const { mutate: mutateToken } = useMutation(getToken, {
        onSuccess: (data) => {
            if (data.message.response_code === '00') {
                localStorage.setItem('token', data?.data?.token)
                localStorage.setItem('refreshToken', data?.data?.refresh_token)
                localStorage.setItem('isLoggedin', false)
            }
        },
    })

    const { mutate: mutateRefreshToken } = useMutation(getRefreshToken, {
        onSuccess: (data) => {
            if (data.message.response_code == '00') {
                localStorage.setItem('isLoggedin', false)
                localStorage.setItem('token', data.data.token)
                localStorage.setItem('refreshToken', data.data.refresh_token)
            } else {
                mutateToken()
            }
        },
    })

    const { mutate: mutateProfile } = useMutation(fetchProfile, {
        onSuccess: (data) => {
            if (data.message.response_code == '00') {
                dispatch(setIsAuthenticated())
                localStorage.setItem('isLoggedin', true)
            } else if (data.message.response_code == '32') {
                mutateToken()
            } else if (data.message.response_code == '62') {
                const refresh_token = localStorage.getItem('refreshToken')
                mutateRefreshToken({ refresh_token })
            }
        },
        retry: 1,
    })

    // const { data: enLang } = useQuery(
    //     ['enlang', token],
    //     () => getEnLanguage({ token }),
    //     {
    //         onSuccess: (data) => {
    //             console.log(data.data)
    //         },
    //     }
    // )
    // const { data: idLang } = useQuery(['idlang', token], () =>
    //     getIdLanguage({ token })
    // )
    // const { data: cnLang } = useQuery(['cnlang', token], () =>
    //     getCnLanguage({ token })
    // )
    // const newLang = {}

    const { data: dataLang, isLoading: isLoadingLang } = useQuery(
        ['language', token],
        () => getLanguage({ token }),
        {
            onSuccess: (data) =>
                data.data.map((lang, idx) => {
                    newLang[lang?.constant] = lang?.value
                }),
        }
    )

    return (
        <AuthContext.Provider value={{ token, newLang }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
