import { api } from 'Utils/axios'

export const setLogin = ({ payload, token }) => {
    return api
        .post(
            '/authorization/user-app/login',
            {
                email: payload?.email,
                password: payload?.password,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const setRegister = ({ payload, token }) => {
    return api
        .post(
            '/user-app',
            { ...payload },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const setLogout = ({ token }) => {
    return api
        .post(
            '/authorization/user-app/logout',
            {},
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const setForgotPassword = ({ token, payload }) => {
    return api
    .post(
            '/authorization/user-app/forgot-password',
            { ...payload },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
            )
    .then((res) => res?.data)
}

export const setResetPassword = ({ token, payload, tokenForgot }) => {
    return api
    .put(
            '/authorization/user-app/reset-password',
            { ...payload },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                    'nihao-reset-password-token': tokenForgot
                },
            }
            )
    .then((res) => res?.data)
}