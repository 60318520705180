import { api } from 'Utils/axios'

export const getEnLanguage = ({ token }) =>
    api
        .post(
            '/backoffice/mapping-language-admin/list',
            {
                set_constant: false,
                constant: '',
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                    'is-all-language': 0,
                },
            }
        )
        .then((res) => res?.data)

export const getIdLanguage = ({ token }) =>
    api
        .post(
            '/backoffice/mapping-language-admin/list',
            {
                set_constant: false,
                constant: '',
            },
            {
                headers: {
                    language: 'ID',
                    'nihao-token': token,
                    'is-all-language': 0,
                },
            }
        )
        .then((res) => res?.data)

export const getCnLanguage = ({ token }) =>
    api
        .post(
            '/backoffice/mapping-language-admin/list',
            {
                set_constant: false,
                constant: '',
            },
            {
                headers: {
                    language: 'CN',
                    'nihao-token': token,
                    'is-all-language': 0,
                },
            }
        )
        .then((res) => res?.data)

export const getLanguage = ({ token }) =>
    api
        .get(
            '/mapping-language-mobile',
            // {
            //     set_constant: false,
            //     constant: '',
            // },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                    'is-all-language': 0,
                },
            }
        )
        .then((res) => res?.data)
