import { api } from 'Utils/axios'
import { useQuery, useMutation } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { deviceTypeDesc } from 'Utils/deviceType'

const fetchToken = async () => {
    return api
        .post(
            '/token/auth',
            {
                app_name: process.env.REACT_APP_NAME,
                app_key: process.env.REACT_APP_KEY,
                device_id: uuidv4(),
                device_type: deviceTypeDesc.deviceType,
            },
            {
                headers: {
                    language: 'EN',
                },
            }
        )
        .then((res) => res?.data)
}

export const useToken = ({ options }) => {
    return useMutation(() => fetchToken(), {
        ...options,
    })
}

export const getToken = () =>
    api.post(
        '/token/auth',
        {
            app_name: process.env.REACT_APP_NAME,
            app_key: process.env.REACT_APP_KEY,
            // app_key: 'waw',
            device_id: uuidv4(),
            device_type: deviceTypeDesc.deviceType,
        },
        {
            headers: {
                language: 'EN',
            },
        }
    ).then((res) => res?.data)
    
export const getRefreshToken = ({refresh_token}) => {
    return api.get('/token/refresh', {
        headers: {
            language: 'EN',
            'refresh-token': refresh_token,
        },
    }).then((res) => res?.data)
}
