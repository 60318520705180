import React, { useContext, useState } from 'react'
import userLogo from 'Assets/images/user.png'
import PointDownSvg from 'Assets/images/point-down.svg'
import ProfileDropSvg from 'Assets/images/profile-drop.svg'
import PasswordDropSvg from 'Assets/images/password-drop.svg'
import TransactionDropSvg from 'Assets/images/transaction-drop.svg'
import NotificationDropSvg from 'Assets/images/notification-drop.svg'
import FavoriteDropSvg from 'Assets/images/favorite-drop.svg'
import LogoutDropSvg from 'Assets/images/logout-drop.svg'
import CopyIconSvg from 'Assets/images/copy-icon.svg'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Toast from 'Utils/toast'
import { AuthContext } from 'Context/authContext'

const DropdownPorfile = ({ data, handleLogout }) => {
    const navigate = useNavigate()
    const { newLang } = useContext(AuthContext)
    // console.log(data.data.referral_code)
    // const [refCode, setRefCode] = useState('ADF12144123')

    const profile = data
    return (
        <div className='dropdown dropdown-end'>
            <label
                tabIndex={0}
                className='flex items-center text-white bg-white min-w-[100px] px-1 py-3 text-sm font-normal normal-case rounded-lg justify-around cursor-pointer'
            >
                <img
                    className='rounded-full w-8 aspect-square'
                    src={
                        profile?.data?.profile_picture_image_url
                            ? profile?.data?.profile_picture_image_url
                            : userLogo
                    }
                    alt=''
                />
                <span className='text-blue-five font-bold text-ss'>
                    {profile?.data?.first_name}
                </span>
                <img className='w-3' src={PointDownSvg} alt='' />
            </label>
            <div
                tabIndex={0}
                className='dropdown-content card card-compact shadow bg-white mt-2'
            >
                <div className='card-body flex flex-row !p-0'>
                    <div className='store text-base font-normal text-black flex flex-col gap-2'>
                        <div className='profile-drop min-w-[330px] flex-row shadow-2xl'>
                            <div className='head text-center bg-gray-400 p-2 rounded-tl-lg rounded-tr-lg'>
                                Account
                            </div>
                            <div className='foot bg-white p-5 w-full rounded-bl-lg rounded-br-lg'>
                                <div className='ref-code w-full'>
                                    <span className='font-bold text-ss'>
                                        {newLang?.referralCode}
                                    </span>
                                    <InputGroup>
                                        <Input
                                            className='!text-blue-five !text-base !font-semibold'
                                            value={profile?.data?.referral_code}
                                            disabled
                                        />
                                        <InputRightElement>
                                            <img
                                                className='w-5 cursor-pointer'
                                                src={CopyIconSvg}
                                                alt=''
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        data?.data?.referral_code
                                                    )
                                                    Toast('success', 'copied')
                                                }}
                                            />
                                        </InputRightElement>
                                    </InputGroup>
                                </div>
                                <div className='flex-row mt-4 space-y-2'>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() => navigate('/profile')}
                                    >
                                        <div className='w-2/6'>
                                            <img src={ProfileDropSvg} alt='' />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.editProfile}
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() =>
                                            navigate('/profile/forgot-password')
                                        }
                                    >
                                        <div className='w-2/6'>
                                            <img src={PasswordDropSvg} alt='' />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.password}
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() =>
                                            navigate('/profile/transaction')
                                        }
                                    >
                                        <div className='w-2/6'>
                                            <img
                                                src={TransactionDropSvg}
                                                alt=''
                                            />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.transaction}
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() =>
                                            navigate('/profile/notification')
                                        }
                                    >
                                        <div className='w-2/6'>
                                            <img
                                                src={NotificationDropSvg}
                                                alt=''
                                            />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.notification ??
                                                    'Notification'}
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() =>
                                            navigate('/profile/favorite')
                                        }
                                    >
                                        <div className='w-2/6'>
                                            <img src={FavoriteDropSvg} alt='' />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.favorite}
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className='flex cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        onClick={() => handleLogout()}
                                    >
                                        <div className='w-2/6'>
                                            <img src={LogoutDropSvg} alt='' />
                                        </div>
                                        <div className='w-4/6 m-auto'>
                                            <h1 className='font-bold text-sm'>
                                                {newLang?.logout}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DropdownPorfile
