import { api } from 'Utils/axios'

export const fetchProfile = ({ token }) => {
    return api
        .get('/user-app/profile', {
            headers: {
                language: 'EN',
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchChangeProfile = ({ token, payload }) => {
    return api
        .put(
            '/user-app/profile',
            {
                ...payload,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchChangePassword = ({ token, payload }) => {
    return api
        .put(
            '/user-app/profile/change-password',
            {
                ...payload,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchFavorite = ({ token, query, options }) => {
    return api
        .post(
            '/advertising/like/list',
            {
                filter: {
                    ...query,
                },
                ...options,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPoint = ({ token }) => {
    return api
        .get('/user-app/point/balance', {
            headers: {
                language: 'EN',
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchTopup = ({ token, payload }) => {
    return api
        .post(
            '/user-app/point/top-up',
            {
                ...payload,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPaymentTopup = ({ token, id }) => {
    return api
        .put(
            `user-app/point/payment/${id}`,
            {},
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchListPoint = ({ token, options }) => {
    return api
        .post(
            '/user-app/point/list',
            {
                ...options,
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchTopupVoucher = ({ token, payload }) => {
    console.log(token, payload)
    return api
        .post(
            `user-app/point/top-up-voucher`,
            {
                ...payload
            },
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}
