import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import skeletonPreview from 'Assets/images/default_preview_thumbnail.svg'
import { AuthContext } from 'Context/authContext'

const Card = ({ data, type, size }) => {
    const { newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const typeHandler = (type) => {
        switch (type) {
            case 'otherAds':
                return 'grid grid-cols-2 md:grid-cols-5 gap-4'
            case 'homeAds':
                return 'flex no-wrap gap-4'
            case 'vipAds':
                return 'flex no-wrap overflow-auto gap-4'
            default:
                return 'flex flex-wrap gap-4'
        }
    }

    const sizeHandler = (type) => {
        // w-[170px] min-w-[170px] md:w-[240px]
        switch (type) {
            case 'vipAds':
                return 'min-w-[240px] max-w-[240px] md:min-w-[300px] max-w-[300px]'
            // return ''
            default:
                // return 'min-w-[260px] md:w-[240px]'
                return 'min-w-[160px] max-w-[160px] md:min-w-[240px] max-w-[240px]'
        }
    }

    const imageHandler = (type) => {
        // w-[175px] md:w-[240px] h-[135px] md:h-[180px]
        // switch (type) {
        //     case 'vipAds':
        //         return 'w-[224px] md:w-[356px]'
        //     default:
        //         return 'w-[175px] md:w-[240px] h-[135px] md:h-[180px]'
        // }
    }

    const handleNavigate = (slug) => {
        navigate(`/${slug}`)
    }
    return (
        <>
            <div className={`${typeHandler(type)} m-auto`}>
                {/* <div className='grid grid-cols-2 md:grid-cols-5'> */}
                {data?.map((item) => (
                    <div
                        // className='w-[170px] min-w-[170px] md:w-[240px] mb-4 rounded-2xl bg-white shadow-md text-black cursor-pointer hover:scale-95 ease-in-out duration-300'
                        className={`${sizeHandler(
                            type
                        )} gap-4 rounded-2xl bg-white shadow-md text-black cursor-pointer hover:scale-95 ease-in-out duration-300`}
                        // onClick={() => {
                        //     navigate(`/${item.slug}`)
                        //     location.reload()
                        // }}
                        onClick={() => handleNavigate(item.slug)}
                        key={item.id}
                    >
                        <div className='card-header relative'>
                            <div className='card-image aspect-4/3'>
                                <img
                                    // className={`aspect-4/3 object-cover rounded-t-2xl ${imageHandler(
                                    //     type
                                    // )}`}
                                    className={`object-fill rounded-t-2xl w-full h-full ${imageHandler(
                                        type
                                    )}`}
                                    src={
                                        item.image_website?.thumbnail ||
                                        skeletonPreview
                                    }
                                    alt=''
                                />
                            </div>
                            <div className='absolute top-2 left-2 shadow-md'>
                                <span className='badge bg-white text-[8px] font-normal text-black border-none'>
                                    {item.category_name}
                                </span>
                            </div>
                        </div>
                        <div className='card-body p-3'>
                            <div className='card-title'>
                                <span className='text-xs font-bold leading-4'>
                                    {item.title}
                                </span>
                            </div>
                            <div className='card-desc'>
                                <span className='text-ss font-medium leading-4 line-clamp-3'>
                                    {item.description_preview}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Card
