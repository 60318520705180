import { api } from "Utils/axios"

export const fetchListHomeSection = ({ token, query, options }) => {
    return api
        .post(
            'home-section/list',
            {
                filter: {
                    ...query,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchListBanner = ({ token, query, options }) => {
    return api
        .post(
            'advertising-banner/list',
            {
                filter: {
                    ...query,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchBanner = ({ token, id }) => {
    return api
        .get(
            `advertising-banner/${id}`,
            // {
            //     filter: {
            //         ...query
            //     },
            //     ...options
            // },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}