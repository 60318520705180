import { api } from 'Utils/axios'

//List Advertise
export const fetchListAdvertise = ({ token, query, options }) => {
    return api
        .post(
            'advertising/list',
            {
                filter: {
                    // set_title: false,
                    // title: '',
                    // set_category: false,
                    // category: 1,
                    // set_status: false,
                    // status: 1,
                    // set_deleted: true,
                    ...query,
                },
                // limit: limit,
                // page: page,
                // order: 'created_at',
                // sort: 'ASC',
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchListAdvertiseCat = (token, query, options, id) => {
    return api
        .post(
            'advertising/list',
            {
                filter: {
                    // set_title: false,
                    // title: '',
                    // set_category: false,
                    // category: 1,
                    // set_status: false,
                    // status: 1,
                    // set_deleted: true,
                    ...query,
                    set_home_section: true,
                    home_section: id,
                },
                // limit: limit,
                // page: page,
                // order: 'created_at',
                // sort: 'ASC',
                ...options,
                limit: 4,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchAdvertise = ({ token, slug }) => {
    return api
        .get(
            `advertising/slug/${slug}`,
            // {
            //     filter: {
            //         ...query
            //     },
            //     ...options
            // },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchAdvertiseCategory = ({ token }) => {
    return api
        .post(
            `advertising/category`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchAdvertiseRow = ({ token }) => {
    return api
        .post(
            `advertising-row/list`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchTags = ({ token }) => {
    return api
        .get(`advertising/tags`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchViewAds = ({ token, id }) => {
    return api
        .put(
            `advertising/view/${id}`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchIsLike = ({ token, id }) => {
    return api
        .get(`advertising/is-like/${id}`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchLike = ({ token, id }) => {
    return api
        .put(
            `advertising/like/${id}`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchUnlike = ({ token, id }) => {
    return api
        .put(
            `advertising/unlike/${id}`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchListCatWithTotal = ({ token }) => {
    return api
        .get(`advertising/category/with-total-data`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}
