import React, { useContext } from 'react'
import PpobPulsa from 'Assets/images/ppob-pulsa.svg'
import PpobPaketData from 'Assets/images/ppob-paketData.svg'
import PpobPascaBayar from 'Assets/images/ppob-pascaBayar.svg'
import PpobInternet from 'Assets/images/ppob-internet.svg'
import PpobTokenListrik from 'Assets/images/ppob-tokenListrik.svg'
import Imei from 'Assets/images/IMEI.png'
import PpobTv from 'Assets/images/ppob-tv.svg'
import PpobBpjs from 'Assets/images/ppob-bpjs.svg'
import PpobPdam from 'Assets/images/ppob-pdam.svg'
import PpobEmoney from 'Assets/images/ppob-emoney.svg'
import LogoIMEI from 'Assets/images/Logo-IMEI.png'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from 'Context/authContext'

const TopUpTagihan = () => {
    const { newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const data = [
        // {
        //     img: LogoIMEI,
        //     name: newLang?.labelIMEI ?? 'Registration Form BC 2.2',
        //     path: '/imei',
        // },
        {
            img: PpobPulsa,
            name: newLang?.phoneAirtime ?? 'Credit',
            path: '/credit',
        },
        {
            img: PpobPaketData,
            name: newLang?.phoneData ?? 'Phone Data',
            path: '/credit-data',
        },
        {
            img: PpobPascaBayar,
            name: newLang?.postpaid ?? 'Pascabayar',
            path: '/credit-hp',
        },
        {
            img: PpobEmoney,
            name: newLang?.titleEmoney ?? 'E-Wallet',
            path: '/credit-emoney',
        },
        {
            img: PpobInternet,
            name: newLang?.internet ?? 'Internet',
            path: '/credit-net',
        },
        {
            img: PpobTokenListrik,
            name: newLang?.prepaidElectricity ?? 'Token Listrik',
            path: '/credit-token',
        },
        {
            img: PpobTv,
            name: newLang?.tvPascabayar ?? 'Tv Pascabayar',
            path: '/credit-tv',
        },
        {
            img: PpobBpjs,
            name: newLang?.bpjs ?? 'BPJS',
            path: '/credit-bpjs',
        },
        {
            img: PpobPdam,
            name: 'PDAM',
            path: '/credit-pdam',
        },
    ]

    return (
        <div className='flex flex-col border-1 md:border-2 md:border-black-seven/[.55] p-2 md:p-5 rounded-xl w-full md:w-1/2 md:h-[220px]'>
            <div className='flex justify-between'>
                <span className='text-black-two text-sm font-bold hidden md:flex'>
                    {newLang?.topupBills}
                </span>
                {/* <span className='text-black-two text-xs font-bold hidden md:flex'>
                    View All
                </span> */}
            </div>
            <div className='flex p-4 space-x-10 md:space-x-20 overflow-scroll'>
                {data.map((item, idx) => (
                    <div
                        className=''
                        key={idx}
                        onClick={() => navigate(item.path)}
                    >
                        <div className='flex flex-col w-14 md:w-16'>
                            <img
                                className='items-center min-w-60'
                                src={item.img}
                                alt='Pulsa'
                            />
                            {/* {item.img} */}
                            <span className='text-black-two text-[8px] md:text-xs pt-2 text-center font-normal'>
                                {item.name}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopUpTagihan
