import { useContext, useEffect, useState, useRef } from 'react'
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom'
import MainLogo from 'Assets/images/logoNihao.svg'
import NotifLogo from 'Assets/images/notif-logo.svg'
import LocationLogo from 'Assets/images/location-logo.svg'
import PhoneLogo from 'Assets/images/phone-logo.svg'
import PusatBantuanLogo from 'Assets/images/pusat-bantuan.svg'
import Bendera from 'Assets/images/bendera.svg'
import Cross from 'Assets/images/cross.svg'
import Chevron from 'Assets/images/chevron.svg'
import CopySvg from 'Assets/images/copy.svg'
import ArrowDownSvg from 'Assets/images/arrow-down.svg'
import englishlogo from 'Assets/images/english.png'
import indoLogo from 'Assets/images/indonesia.png'
import chinaLogo from 'Assets/images/china.png'
import PlusTopUpSvg from 'Assets/images/PlusTopUp.svg'
import TransferSvg from 'Assets/images/Transfer.svg'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchAdvertiseCategory, fetchListAdvertise } from 'Api/advertise'
import AppStoreLogo from 'Assets/images/appstore.svg'
import PlayStoreLogo from 'Assets/images/playstore.svg'
import qrCode from 'Assets/images/qrcode.jpeg'
import { fetchPoint, fetchProfile } from 'Api/profile'
import { setLogout } from 'Api/auth'
import { setIsNotAuthenticated } from 'Store/authenticated'
import Toast from 'Utils/toast'

import Card from './Card'
import DropdownPoint from './Header/dropdownPoint'
import DropdownPorfile from './Header/dropdownPorfile'
import { toValue } from 'Utils/conversion'
import RoundSvg from 'Assets/images/round.svg'
import PointDownSvg from 'Assets/images/point-down.svg'

const Header = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const ref = useRef(null)
    const menu = useRef(null)
    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    const [navOpen, setNavOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [showDownload, setShowDownload] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [selectedLang, setSelectedLang] = useState('')

    const languages = [
        {
            lang: `Indonesia`,
            img: indoLogo,
        },
        {
            lang: `English`,
            img: englishlogo,
        },
        {
            lang: `Chinese`,
            img: chinaLogo,
        },
    ]

    const menus = [
        {
            id: 1,
            title: newLang?.editProfile,
            path: '/profile',
            childs: null,
        },
        {
            id: 2,
            title: newLang?.password,
            path: 'profile/forgot-password',
            childs: null,
        },
        {
            id: 3,
            title: newLang?.transaction,
            path: 'profile/transaction',
            childs: [
                { id: 31, title: 'Prepaid', path: 'prayabar' },
                { id: 32, title: 'Postpaid', path: 'token' },
            ],
        },
        {
            id: 4,
            title: newLang?.notification,
            path: 'profile/notification',
            childs: null,
        },
        {
            id: 5,
            title: newLang?.favorite,
            path: 'profile/favorite',
            childs: null,
        },
    ]

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_category: false,
        // category: 1,
        category: [],
        set_tag: false,
        tags: '',
        set_status: true,
        status: 1,
    })

    const [options, setOptions] = useState({
        limit: 3,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        const lang = localStorage.getItem('lang')
        if (lang == 'ID') {
            setSelectedLang('0')
        } else if (lang == 'EN') {
            setSelectedLang('1')
        } else if (lang == 'CN') {
            setSelectedLang('2')
        } else {
            setSelectedLang('2')
        }
    }, [])

    const {
        data: advertisesData,
        isLoading: isLoadingAdvertisesData,
        refetch: refetchAdvertisesData,
    } = useQuery(
        ['advertises', query, token],
        () => fetchListAdvertise({ token, query, options }),
        {
            onSuccess: (data) => {},
        }
    )

    const {
        data: advertiseCategoryList,
        isLoading: isLoadingAdvertiseCategoryList,
    } = useQuery(
        ['advertises-category', token],
        () => fetchAdvertiseCategory({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    const { data: point, isLoading: isLoadingPoint } = useQuery(
        ['point', token],
        () => fetchPoint({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    const { mutate: mutateLogout, isLoading: isLoadingLogout } = useMutation(
        setLogout,
        {
            onSuccess: (data) => {
                dispatch(setIsNotAuthenticated())
                // Toast('success', data?.message?.response_message)
                localStorage.setItem('isLoggedin', false)
                navigate('/')
            },
        }
    )

    const handleLogout = () => {
        mutateLogout({ token })
    }

    const handleSearch = async (e) => {
        // ;(await (e != ''))
        //     ? setIsOpenSearchbar(true)
        //     : setIsOpenSearchbar(false)
        await setQuery({ ...query, set_title: true, title: e })
        refetchAdvertisesData()
    }

    const openChevron = (id) => {
        const childs = document.querySelectorAll(`.child`)

        childs.forEach((child) => {
            if (
                child.classList.contains(`${id}`) &&
                child.classList.contains('child') &&
                child.classList.contains('hidden')
            ) {
                child.classList.remove('hidden')
            } else {
                child.classList.add('hidden')
            }
        })
    }

    const handleSearchMobile = () => {
        isMobile && navigate('/search')
    }

    const handleSearchNavigate = (id) => {
        if (location.pathname.includes('advertise-list')) {
            navigate(`${location.pathname}?cat=${id}`)
            window.location.reload()
        } else {
            navigate(`advertise-list?cat=${id}`)
        }
    }

    const handleChangeLang = ({ index, lang }) => {
        setSelectedLang(`${index}`)
        if (lang.lang == 'Indonesia') {
            localStorage.setItem('lang', 'ID')
            navigate(0)
        } else if (lang.lang == 'English') {
            localStorage.setItem('lang', 'EN')
            navigate(0)
        } else if (lang.lang == 'Chinese') {
            localStorage.setItem('lang', 'CN')
            navigate(0)
        }
    }

    return (
        <nav className='bg-[#213267] fixed w-full z-20 pin-t flex flex-col'>
            <div className='hidden md:flex items-center justify-between flex-nowrap w-full max-w-screen-xl m-auto p-2'>
                <div className='dropdown dropdown-hover'>
                    <label
                        tabIndex={0}
                        className='flex items-center cursor-pointer text-white'
                    >
                        <img className='mr-2 w-3 h-3' src={PhoneLogo} alt='' />
                        <span className='text-xs font-normal whitespace-nowrap'>
                            {newLang?.downloadApplication ??
                                'Download Application Nihao'}
                        </span>
                    </label>
                    <div
                        tabIndex={0}
                        className='dropdown-content card card-compact p-2 shadow bg-white'
                    >
                        <div className='card-body flex flex-row'>
                            {/* <h3 className='card-title'>Card title!</h3>
                            <p>you can use any element as a dropdown.</p> */}
                            <div className='barcode flex flex-col'>
                                <img
                                    className='max-w-[180px]'
                                    src={qrCode}
                                    alt=''
                                />
                                <button
                                    className='bg-blue-five text-white rounded-md text-sm py-2 px-4'
                                    onClick={() =>
                                        window.open(
                                            'https://nihaoid.oss-ap-southeast-5.aliyuncs.com/nihao/nihao_id_v1.apk'
                                        )
                                    }
                                >
                                    {newLang?.download ?? 'Download'} NIHAO ID
                                </button>
                            </div>
                            <div className='store text-base font-normal text-black flex flex-col'>
                                <div className='text-center'>
                                    <span>
                                        {newLang?.scanQr ??
                                            'Scan this QR or download app from'}
                                    </span>
                                </div>
                                <div className='flex flex-col gap-2 justify-center m-auto'>
                                    <div
                                        className='cursor-pointer'
                                        onClick={() =>
                                            window.open(
                                                'https://apps.apple.com/us/app/nihao-id/id6443483686'
                                            )
                                        }
                                    >
                                        <img
                                            className='max-w-[140px]'
                                            src={AppStoreLogo}
                                            alt=''
                                        />
                                    </div>
                                    <div
                                        className='cursor-pointer'
                                        onClick={() =>
                                            window.open(
                                                'https://play.google.com/store/apps/details?id=id.nihao.nihao'
                                            )
                                        }
                                    >
                                        <img
                                            className='max-w-[140px]'
                                            src={PlayStoreLogo}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-8 text-white'>
                    <Link to='/privacy-policy'>
                        <span className='text-xs font-normal whitespace-nowrap'>
                            {newLang?.privacyPolicy ?? 'Privacy & Policy'}
                        </span>
                    </Link>
                    <Link to='/about-us'>
                        <span className='text-xs font-normal whitespace-nowrap'>
                            {newLang?.aboutUs ?? 'About Us'}
                        </span>
                    </Link>
                    <Link to='/terms-condition'>
                        <span className='text-xs font-normal whitespace-nowrap'>
                            {newLang?.termsCondition}
                        </span>
                    </Link>
                    {/* <Link to='/faq'>
                        <span className='text-xs font-normal whitespace-nowrap'>
                            FAQ
                        </span>
                    </Link> */}
                    {location.pathname == '/' && (
                        <div className='dropdown dropdown-hover dropdown-end m-auto'>
                            <label
                                tabIndex={0}
                                className='text-white text-xs flex items-center'
                            >
                                <img
                                    className='w-5 mr-2'
                                    src={
                                        selectedLang &&
                                        languages[parseInt(selectedLang)]?.img
                                    }
                                    alt=''
                                />
                                <span>
                                    {selectedLang &&
                                        languages[parseInt(selectedLang)]?.lang}
                                </span>
                                <img
                                    className='text-white ml-2'
                                    src={ArrowDownSvg}
                                    alt=''
                                />
                            </label>
                            <ul
                                tabIndex={0}
                                className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'
                                defaultValue={2}
                            >
                                {languages.map((lang, index) => (
                                    <li
                                        className=''
                                        key={index}
                                        onClick={() =>
                                            handleChangeLang({ index, lang })
                                        }
                                    >
                                        <a className='text-blue-five active:bg-slate-500'>
                                            <img
                                                className='w-5 h-5'
                                                src={lang.img}
                                                alt=''
                                            />
                                            {lang.lang}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex justify-around md:justify-between items-center flex-nowrap w-full max-w-screen-xl p-2 m-auto'>
                {/* logo */}
                <Link className='w-1/12 hidden md:flex ' to='/'>
                    <img className='min-w-[50px]' src={MainLogo} alt='' />
                </Link>
                {/* search & category */}
                <div
                    className='w-7/12 md:w-full p-2 md:p-4 flex flex-col'
                    ref={ref}
                >
                    <div className='w-full relative'>
                        <input
                            type='text'
                            placeholder='Search in Nihao'
                            className='input inputdered bg-white text-black w-full rounded-lg'
                            onChange={(e) => handleSearch(e.target.value)}
                            // onClick={handleSearchMobile}
                            onClick={() => {
                                setShow(!show)
                            }}
                        />
                        {show && (
                            <div className='w-full hidden md:flex p-4 bg-white rounded-lg absolute top-14 shadow-xl'>
                                <div className='flex flex-col'>
                                    {/* kategori Rekomendasi */}
                                    <div className='flex flex-col gap-2'>
                                        <span className='text-lg font-bold'>
                                            {newLang?.category ?? `Categories`}
                                        </span>
                                        <div className='flex my-4 overflow-auto space-x-2'>
                                            {!isLoadingAdvertiseCategoryList &&
                                                advertiseCategoryList.data?.map(
                                                    (item, idx) => (
                                                        <div
                                                            className={`py-1 px-6 rounded-[10px] cursor-pointer hover:scale-95 ease-in-out duration-300`}
                                                            style={{
                                                                background: `#213267`,
                                                            }}
                                                            key={idx}
                                                            onClick={() => {
                                                                setShow(!show)
                                                                handleSearchNavigate(
                                                                    item.id
                                                                )
                                                            }}
                                                        >
                                                            <span className='text-xs md:text-sm text-white font-normal whitespace-nowrap'>
                                                                {item.title}
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                    {/* Iklan yang banyak di cari */}
                                    <div className='flex flex-col gap-2'>
                                        <span className='text-lg font-bold'>
                                            {newLang?.lastSeenAds ??
                                                'Last Seen Ads'}
                                        </span>
                                        <div className='flex space-x-3 overflow-auto md:overflow-hidden'>
                                            <Card data={advertisesData?.data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <ul className='mt-4 hidden md:flex gap-8 text-white'>
                        <li>
                            <span className='text-xs font-normal whitespace-nowrap'>
                                Category 1
                            </span>
                        </li>
                        <li>
                            <span className='text-xs font-normal whitespace-nowrap'>
                                Category 1
                            </span>
                        </li>
                        <li>
                            <span className='text-xs font-normal whitespace-nowrap'>
                                Category 1
                            </span>
                        </li>
                    </ul> */}
                </div>
                {/* notif , login & alamat */}
                <div className='w-4/12 flex flex-col'>
                    <div className='flex justify-around w-full'>
                        <img
                            className='hover:cursor-pointer w-6'
                            src={NotifLogo}
                            alt=''
                        />
                        {!isLoggedin && (
                            <div className='bg-white w-[2px] hidden md:flex' />
                        )}
                        <div className='hidden md:flex space-x-4 relative'>
                            {!isLoggedin ? (
                                <div className='flex gap-2'>
                                    <Link className='' to='/login'>
                                        <button className='btn min-w-[100px] text-sm font-normal normal-case rounded-full bg-white text-blue-five '>
                                            {newLang?.login}
                                        </button>
                                    </Link>
                                    <Link to='/register'>
                                        <button className='btn btn-outline min-w-[100px] text-sm font-normal normal-case rounded-full bg-transparent text-white'>
                                            {newLang?.register}
                                        </button>
                                    </Link>
                                </div>
                            ) : (
                                <>
                                    {/* <DropdownPoint data={!isLoadingPoint && point}/> */}
                                    <label
                                        tabIndex={0}
                                        className='flex items-center text-white bg-white min-w-[100px] px-1 py-3 text-sm font-normal normal-case rounded-lg justify-around cursor-pointer'
                                        onClick={() => navigate('topup')}
                                    >
                                        <img
                                            className='w-5'
                                            src={RoundSvg}
                                            alt=''
                                        />
                                        <div className='flex flex-col'>
                                            <span className='text-blue-five font-bold text-ss'>
                                                {newLang?.point}
                                            </span>
                                            <span className='text-blue-five font-bold text-xs'>
                                                {toValue(point?.data)} P
                                            </span>
                                        </div>
                                        <img
                                            className='w-3'
                                            src={PointDownSvg}
                                            alt=''
                                        />
                                    </label>
                                    <DropdownPorfile
                                        data={!isLoadingProfile && profile}
                                        handleLogout={handleLogout}
                                    />
                                </>
                            )}
                        </div>
                        {/* <Link className='flex md:hidden' to='/'>
                            <img className='' src={Bendera} alt='' />
                        </Link> */}
                        {location.pathname == '/' && (
                            <div className='flex md:hidden dropdown dropdown-hover dropdown-end m-auto'>
                                <label
                                    tabIndex={0}
                                    className='text-white text-xs flex items-center'
                                >
                                    <img
                                        className='w-5 mr-2'
                                        src={languages[selectedLang]?.img}
                                        alt=''
                                    />
                                    <img
                                        className='text-white ml-2'
                                        src={ArrowDownSvg}
                                        alt=''
                                    />
                                </label>
                                <ul
                                    tabIndex={0}
                                    className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'
                                    defaultValue={2}
                                >
                                    {languages.map((lang, index) => (
                                        <li
                                            className=''
                                            key={lang.id}
                                            // onClick={() => setSelectedLang(idx)}
                                            onClick={() =>
                                                handleChangeLang({
                                                    index,
                                                    lang,
                                                })
                                            }
                                        >
                                            <a className='text-blue-five active:bg-slate-500'>
                                                <img
                                                    className='w-5 h-5'
                                                    src={lang.img}
                                                    alt=''
                                                />
                                                {lang.lang}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <div
                            className='flex flex-col md:hidden space-y-1'
                            onClick={() => setNavOpen(!navOpen)}
                        >
                            <div className='w-1.5 h-1.5 rounded-full bg-white'></div>
                            <div className='w-1.5 h-1.5 rounded-full bg-white'></div>
                            <div className='w-1.5 h-1.5 rounded-full bg-white'></div>
                        </div>
                    </div>
                    {isLoggedin && !isLoadingProfile && (
                        <div className='hidden md:flex min-h-8 mt-2'>
                            <div className='bg-white rounded-xl px-3 flex items-center w-full'>
                                <img
                                    className='w-3 mr-3'
                                    src={LocationLogo}
                                    alt=''
                                />
                                <span className='truncate text-black-three font-medium text-xs'>
                                    {profile?.data?.address}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={`flex md:hidden side-menu ${
                    !navOpen && `-translate-y-full`
                } absolute top-0 left-0 bg-white w-full h-screen p-4 ease-in-out duration-300 flex flex-col gap-10 shadow-2xl`}
            >
                <div className='nav-head flex gap-4'>
                    <img
                        src={Cross}
                        alt=''
                        onClick={() => setNavOpen(!navOpen)}
                    />
                    <h2 className='text-xl font-bold text-black'>
                        {newLang?.mainMenu ?? 'Main Menu'}
                    </h2>
                </div>
                {isLoggedin && !isLoadingProfile ? (
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-4'>
                            <div className=''>
                                <img
                                    className='rounded-full w-24 h-24 bg-black-six'
                                    src={
                                        profile?.data?.profile_picture_image_url
                                    }
                                    alt=''
                                />
                            </div>
                            <div className='flex flex-col my-2 gap-1'>
                                <span className='font-semibold text-base mb-2'>
                                    {profile?.data?.username}
                                </span>
                                <span className='font-normal text-xs text-black-six'>
                                    {newLang?.refferalCode}
                                </span>
                                <div className='flex flex-row gap-2'>
                                    <span className='font-semibold text-ss'>
                                        {profile?.data?.referral_code}
                                    </span>
                                    <img src={CopySvg} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#f4f4f4] rounded-2xl p-4 flex justify-between'>
                            <div className='flex gap-2'>
                                <img className='w-14' src={RoundSvg} alt='' />
                                <div className='flex flex-col m-auto'>
                                    <span className='text-ss font-bold text-blue-five'>
                                        {newLang?.myPoint}
                                    </span>
                                    <span className='text-sm font-bold text-blue-five whitespace-nowrap'>
                                        {toValue(point?.data)} P
                                    </span>
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='flex flex-col gap-2'>
                                    <img
                                        className='w-8 m-auto'
                                        src={PlusTopUpSvg}
                                        alt=''
                                    />
                                    <span className='text-xs font-bold text-blue-five'>
                                        {newLang?.topup}
                                    </span>
                                </div>
                                {/* <div className='flex flex-col gap-2'>
                                    <img
                                        className='w-8 m-auto'
                                        src={TransferSvg}
                                        alt=''
                                    />
                                    <span className='text-xs font-bold text-blue-five'>
                                        Transfer
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='nav-buttons flex gap-4 justify-around w-full'>
                        <button
                            onClick={() => navigate('/login')}
                            className='btn bg-[#213267] text-white text-xl rounded-full w-40 px-1 py-2 min-h-[1rem] normal-case'
                        >
                            {newLang?.login}
                        </button>
                        <button
                            onClick={() => navigate('/register')}
                            className='btn bg-white text-[#213267] text-xl rounded-full w-40 px-1 py-2 min-h-[1rem] normal-case'
                        >
                            {newLang?.register}
                        </button>
                    </div>
                )}
                <div className='nav-menu flex flex-col gap-4 w-full'>
                    {menus?.map((menu) => (
                        <div className='flex flex-col'>
                            <ul key={menu.id} className={`parent`}>
                                {/* <Link to={menu.path}> */}
                                <div
                                    className='waw'
                                    onClick={() => {
                                        navigate(menu.path)
                                        setNavOpen(!navOpen)
                                    }}
                                >
                                    <li className='flex justify-between'>
                                        <span className='text-black text-xl font-bold'>
                                            {menu.title}
                                        </span>
                                        <img
                                            className={`${menu.id} parent-chevron`}
                                            src={Chevron}
                                            alt=''
                                            onClick={() => openChevron(menu.id)}
                                        />
                                    </li>
                                </div>
                                {/* </Link> */}
                            </ul>
                            {menu.childs &&
                                menu.childs?.map((menuChild) => (
                                    <ul
                                        key={menuChild.id}
                                        className={`child hidden ${menu.id} ease-in-out duration-300 my-2`}
                                    >
                                        <li className='flex justify-between'>
                                            <span className='text-slate-500 text-xl'>
                                                {menuChild.title}
                                            </span>
                                        </li>
                                    </ul>
                                ))}
                        </div>
                    ))}
                    {isLoggedin && !isLoadingProfile && (
                        <span
                            className='text-black text-xl font-bold'
                            onClick={mutateLogout}
                        >
                            {newLang?.logout ?? 'Logout'}
                        </span>
                    )}
                </div>
            </div>
        </nav>
    )
}

export default Header
