import React, { useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'Styles/Carousel.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/lazy'
import { Pagination, Autoplay, Lazy } from 'swiper'
import { AuthContext } from 'Context/authContext'
import { useQuery } from '@tanstack/react-query'
import { fetchListBanner } from 'Api/home'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'

const Carousel = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_status: true,
        status: 1,
    })

    const [options, setOptions] = useState({
        limit: 50,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    const { data: listBannerData, isLoading: isLoadingListBannerData } =
        useQuery(
            ['list-banner', query, token],
            () => fetchListBanner({ token, query, options }),
            {
                onSuccess: (data) => {
                },
            }
        )

    const newListBanner = []

    !isLoadingListBannerData &&
        listBannerData.data?.map((item) => {
            newListBanner.push({
                id: item.id,
                title: item.title,
                image: item.banner?.thumbnail_web,
                desc: item.description,
                date: item.created_at,
            })
        })

    const pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>'
        },
    }

    return (
        <div className='relative'>
            <Swiper
                pagination={pagination}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay, Lazy]}
                className='mySwiper rounded-2xl mb-9'
            >
                {!isLoadingListBannerData ? (
                    newListBanner.map((item) => (
                        <SwiperSlide
                            className='relative cursor-pointer w-full'
                            key={item.id}
                            onClick={() => navigate(`banner/${item.id}`)}
                        >
                            {/* <div className='min-w-[50%] h-40 md:h-80 min-h-full bg-slate-500 p-2 md:p-10 flex flex-col justify-around'>
                                <span className='text-xs md:text-2xl font-bold text-white'>
                                    {item.title}
                                </span>
                                <div
                                    className=''
                                    dangerouslySetInnerHTML={{
                                        __html: item.desc,
                                    }}
                                />
                                <div className='badge bg-[#FFF9F9CC] text-black border-none tex-xs md:text-sm'>
                                    {moment(`${item.date}`).format('MMMM YYYY')}
                                </div>
                            </div> */}
                            <div className='aspect-[16/4] w-full'>
                                <img
                                    className='w-full h-full'
                                    src={item.image}
                                    // src='https://nihaoid.oss-ap-southeast-5.aliyuncs.com/nihao/img/Beta-image-2-R3%20%281%29.png?x-oss-process=style/web-banner'
                                    alt=''
                                />
                            </div>
                        </SwiperSlide>
                    ))
                ) : (
                    <Skeleton width='100%' />
                )}
            </Swiper>
            <div class='swiper-pagination absolute -bottom-20 transform -translate-x-1/2 invisible md:visible'></div>
        </div>
    )
}

/* <SwiperSlide className='relative'>
            <div className='min-w-[50%] h-40 md:h-80 min-h-full bg-[#FF550F] p-2 md:p-10 flex flex-col justify-around'>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Beli Pulsa Dapat
                </span>
                <span className='text-sm md:text-5xl font-bold text-white'>
                    Rumah Impian Motor dan Mobil
                </span>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Ayoo lakuakn Topup Sekarang
                </span>
                <div class='badge bg-[#FFF9F9CC] text-black border-none tex-xs md:text-sm'>
                    Periode April 2022
                </div>
            </div>
            <img
                className='min-w-[50%] h-40 md:h-80 object-fill'
                src='https://images.unsplash.com/photo-1628744301791-d416de069399?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1954&q=80'
                alt=''
            />
        </SwiperSlide>
        <SwiperSlide className=''>
            <div className='min-w-[50%] h-40 md:h-80 min-h-full bg-[#FF550F] p-2 md:p-10 flex flex-col justify-around'>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Beli Pulsa Dapat
                </span>
                <span className='text-sm md:text-5xl font-bold text-white'>
                    Rumah Impian Motor dan Mobil
                </span>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Ayoo lakuakn Topup Sekarang
                </span>
                <div class='badge bg-[#FFF9F9CC] text-black border-none'>
                    Periode April 2022
                </div>
            </div>
            <img
                className='min-w-[50%] h-40 md:h-80 object-fill'
                src='https://images.unsplash.com/photo-1628744301791-d416de069399?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1954&q=80'
                alt=''
            />
        </SwiperSlide>
        <SwiperSlide className=''>
            <div className='min-w-[50%] h-40 md:h-80 min-h-full bg-[#FF550F] p-2 md:p-10 flex flex-col justify-around'>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Beli Pulsa Dapat
                </span>
                <span className='text-sm md:text-5xl font-bold text-white'>
                    Rumah Impian Motor dan Mobil
                </span>
                <span className='text-xs md:text-2xl font-bold text-white'>
                    Ayoo lakuakn Topup Sekarang
                </span>
                <div class='badge bg-[#FFF9F9CC] text-black border-none'>
                    Periode April 2022
                </div>
            </div>
            <img
                className='min-w-[50%] h-40 md:h-80 object-fill'
                src='https://images.unsplash.com/photo-1628744301791-d416de069399?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1954&q=80'
                alt=''
            />
        </SwiperSlide> */

export default Carousel
